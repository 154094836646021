import React, {Component} from 'react';
import Subpage from './Subpage.js'

class Om extends Component {
	render() {
		return (
			<Subpage>
				<h2>Om</h2>
				<p>Marie-Louise Grund er uddannet sanger og musiker fra Det Jyske Musikkonservatorium i 2010, og har siden da specialiseret sig i Sceneoptræden for musikere og coachet et utal af upcoming bands og solister. Dels som selvstændig performance coach, som en del af DMF-support Scenen, og tilknyttet ORAs Audition Days gennem en længere årrække.</p>
				<p>Marie-Louise underviser derudover i Performance i både Valgfag, Sammenspil og Hovedfag på Det Jyske Musikkonservatorium i hhv. Aarhus og Aalborg, og er desuden fast Koncertproducent hos Levende Musik i Skolen. Hun har ydermere været assistent i skrivefasen af den storsælgende bog om sceneoptræden af Kristian Koch “Ud over Scenekanten”.</p>
				<p>Marie-Louise er selv udøvende sanger, komponist og artist under aliaset LHUMA.</p>
			</Subpage>
			)
	}
}

export default Om