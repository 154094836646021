import React, { Component } from 'react';
import imageML from './../../assets/images/ml2022-edit.jpg';
import {connect} from 'react-redux';
import Animate from 'react-move/Animate'

class LargeImage extends Component {
	render() {
		return (
			<Animate 
				start={{opacity: 1}}
				update={{opacity: [this.props.isOnSubpage && window.innerWidth < 700 ? 0 : 1]}}
				>
				{state => {
					return (
						<div id="largeimage" style={{opacity: state.opacity}}>
							<img src={imageML} alt="Marie-Louise Grund"/>
						</div>
					)
				}}
			</Animate>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isOnSubpage: state.isOnSubpage
	}
}

export default connect(mapStateToProps)(LargeImage)
