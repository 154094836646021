import React, { Component } from 'react';
import Animate from 'react-move/Animate'
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

class BreadCrumb extends Component {
	render() {
		return (
			<Animate
				show={this.props.isOnSubpage}
				start={{offset: 10, opacity: 0}}
				enter={{
					offset: [0],
					opacity: [1],
					timing: {duration: 200}
				}}
				leave={{
					offset: [-10],
					opacity: [0],
					timing: {duration: 200}
				}}>
					{state => {
						return (
								<div id="breadcrumb"><Link to="/" style={{opacity: state.opacity, transform: `translateX(${state.offset}px)`}}>Marie-Louise Grund <span>Performance for musikere</span></Link></div>
							)
						}
					}
			</Animate>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isOnSubpage: state.isOnSubpage
	}
}

export default connect(mapStateToProps)(BreadCrumb);