import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import ReactGA from 'react-ga';

class Frontpage extends Component {
	componentDidMount() {
		this.props.dispatch({type: 'IS_ON_SUBPAGE', isOnSubpage: false})
    	ReactGA.initialize('UA-137846194-1');
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		return (
			<div className="frontpage page">
				<h1>Performance for musikere</h1>
				<h2>Marie-Louise Grund</h2>
				<p className="quote">Coaching til bands og solister der vil være bedre til at få deres musik ud over scenekanten</p>
				<div className="btn"><Link to="/undervisning">læs mere</Link></div>
			</div>
			)
	}
}

export default connect()(Frontpage)